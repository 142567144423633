import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import validUrl from 'valid-url'
import qs from 'query-string'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import NotificationAlert from 'react-notification-alert'
import { SEO } from '../components/seo/Seo'
import { QueryParamsList } from '../components/queryParamsList/QueryParamsList'
import { CookiesList } from '../components/cookiesList/CookiesList'
import { StepTop } from '../components/stepTop/StepTop'
import { RequestForm } from '../components/requestForm/RequestForm'
import { Header } from '../components/header/Header'
import { Instructions } from '../components/instructions/Instructions'
import 'react-notification-alert/dist/animate.css'

const MAX_REDIRECTS_COUNT = 10

const IndexPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [url, setUrl] = useState('')
  const [steps, setSteps] = useState([])
  const notifyRef = useRef()
  const process = async ({ goTo, initial }) => {
    try {
      const prevSteps = initial ? [] : steps
      if (prevSteps.length >= MAX_REDIRECTS_COUNT) {
        notify('danger', 'Too many redirects!')
        return setIsLoading(false)
      }
      const link = goTo || url
      const {
        data: {
          error = null,
          hasNext = false,
          location = null,
          cookies = null,
          responseStatus = 500,
        },
      } = await makeRequest(link)
      if (error) {
        notify('danger', error.message)
        setIsLoading(false)
      }
      const urlParams = qs.parse(new URL(link).search)
      setSteps([
        ...prevSteps,
        {
          url: link,
          urlParams,
          nextUrl: location,
          cookies,
          hasNext,
          responseStatus,
        },
      ])
      if (!hasNext) setIsLoading(false)
    } catch (e) {
      notify('danger', e.message)
      setIsLoading(false)
    }
  }
  const notify = (type, message) => {
    return notifyRef.current.notificationAlert({
      place: 'br',
      message,
      type,
      autoDismiss: 5,
    })
  }
  const makeRequest = async (url) => {
    return axios({
      method: 'post',
      url: '/.netlify/functions/track',
      data: { url },
    })
  }
  const handleGo = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (!validUrl.isUri(url)) {
      notify('warning', 'URL is not valid!')
      return setIsLoading(false)
    }
    await process({ initial: true })
  }
  useEffect(() => {
    async function query() {
      if (steps.length > 0) {
        const { hasNext, nextUrl } = steps[steps.length - 1]
        if (hasNext) await process({ goTo: nextUrl })
      }
    }
    query()
  }, [steps])

  return (
    <>
      <SEO
        title='Redirects.show'
        keywords={[
          'redirects.show',
          'redirect',
          'redirects',
          'trace',
          'tracker',
          'developer',
          'devops',
          'nodejs',
          'react',
        ]}
      />
      <Header />
      <Container>
        <NotificationAlert ref={notifyRef} />
        <Row style={{ margin: '15px 0' }}>
          <Col md={{ span: 6, offset: 3 }}>
            <RequestForm
              isLoading={isLoading}
              submitForm={handleGo}
              handleChangeUrl={setUrl}
              url={url}
            />
            {steps.length === 0 && <Instructions />}

            <Accordion>
              {steps.map((s, i) =>
                s.url ? (
                  <Card key={i}>
                    <StepTop
                      hasNext={s.hasNext}
                      url={s.url}
                      responseStatus={s.responseStatus}
                      hasQueryParams={
                        s.urlParams && Object.entries(s.urlParams).length > 0
                      }
                      hasCookiesData={s.cookies && s.cookies.length > 0}
                      accordionId={i}
                    />
                    {s.urlParams && Object.entries(s.urlParams).length > 0 && (
                      <QueryParamsList
                        queryParamsData={s.urlParams}
                        accordionId={i}
                      />
                    )}
                    {s.cookies && s.cookies.length > 0 && (
                      <CookiesList cookiesData={s.cookies} accordionId={i} />
                    )}
                  </Card>
                ) : null
              )}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default IndexPage
